import React,{useState} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import translate from "../../../../../../modules/translate";
import Text from "../../../../../../components/Text";
import SkeletonLiveData from "../../../../components/LiveData/skeleton";
import ForecastPrognosisSkeleton
  from "../../../../components/Forecast/views/ForecastPrognosis/views/ForecastPrognosisSkeleton";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import isStolApp from "../../../../../../modules/is-stolapp";



const Wrapper = styled.div`
  
  font-family: ${(props) => (props.theme.font.primary)};
`


const ReloadLocationButton=styled.div`
  font-family: ${(props) => (props.theme.font.primary)};
     padding: 15px 35px;
    cursor: pointer;
    background-color: #d41e25;
    border: unset;
    text-align:center;
    border-radius: 4px;
    color: white;
    height: calc(100% - 1em);
    width:100px;
  margin-left:auto;
  margin-right:auto;

`

const StyledText = styled(Text)`
  padding:20px;
  font-size: ${(props) => props.theme.fontSize.small};
  text-align: center;
`
const StyledBox = styled(Box)`
  position: absolute;
  z-index: 9000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius:15px;
  background-color: #eee;
  width: 300px;
  padding:15px;
    font-family: ${(props) => (props.theme.font.primary)};
  @media (max-width: 800px) {
    top: unset;
    left: unset;
    transform: unset;
    width: 100%;
    height: 100%;
  }
  border: none;
`
const CrossDiv = styled.div`
  position: absolute;
  top: -26px;
  right: -27px;
  opacity: 0.8;
  color: #afafaf;
  font-size: 30px;
  width: 18px;

  margin-left: auto;
  :after {
    background-color: #fff;

    content: '';
    display: block;
    height: 2px;
    margin: 7px 0;

    transform: translateY(-4px) rotate(135deg);
    :hover {
      background-color: #d41e25;
    }
  }

  :before {
    background-color: #fff;

    content: '';
    display: block;
    height: 2px;
    margin: 7px 0;

    transform: translateY(5px) rotate(-135deg);
    :hover {
      background-color: #d41e25;
    }
  }

  cursor: pointer;
  @media (max-width: 800px) {
    top: 70px;
    right: 19px;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    top: 67px;
    right: 10px;
  }
`

const ScreenNoGeoLocation = ({run,...props}) => {
  const [modalOpen,setModalOpen]=useState(true)

  let settingsNotificationWeb = translate("weather_geo_notification_web_settings")
  let settingsNotificationApp = translate("weather_geo_notification_app_settings")

  function handleClose(e) {
    setModalOpen(false)
  }
  return(
    <Wrapper {...props}>
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClick={handleClose}
        {...props}
      ><StyledBox><StyledText>{translate("weather_geo_notification")}</StyledText> <ReloadLocationButton onClick={handleClose}>{translate("weather_geo_notification_accept")}</ReloadLocationButton>  <CrossDiv onClick={handleClose}></CrossDiv></StyledBox></Modal>
      <StyledText>{isStolApp() ? settingsNotificationApp : settingsNotificationWeb}</StyledText>
      <SkeletonLiveData  onClick={()=>{setModalOpen(true)}}></SkeletonLiveData>
      <ForecastPrognosisSkeleton onClick={()=>{setModalOpen(true)}}></ForecastPrognosisSkeleton>

    </Wrapper>
)}

export default ScreenNoGeoLocation
