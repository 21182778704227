import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Text from '../../../../../../../../components/Text'
import moment from 'moment/moment'
import { Motion, spring } from 'react-motion'
import Delay from '../../../../../../../../components/Delay'
import translate from '../../../../../../../../modules/translate'

const TIME_FORMAT = 'kk:mm'

function timeToCoordinates(now, startTime, endTime) {
  const GAP_DEGREE = 4
  const radius = 0.5
  const height = 0.18
  const differenceTime = endTime - startTime
  const searchedTime = now - startTime
  const cos = (radius - height) / radius
  const degreeCut = (Math.asin(cos) / Math.PI) * 180
  const startDegree = 180 + GAP_DEGREE + degreeCut
  const endDegree = 360 - GAP_DEGREE - degreeCut
  const differenceDegree = endDegree - startDegree
  const searchedDegree = (searchedTime * differenceDegree) / differenceTime
  const degree = startDegree + searchedDegree
  const radian = (degree * Math.PI) / 180
  const top = Math.sin(radian)
  const left = Math.cos(radian)
  return [top, left]
}
const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: center;
  width: 100%;
  max-width: 300px;
`
const StyledTextTime = styled(Text)`
  font-size: ${(props) => props.theme.fontSize.base};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.base};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.base};
  }
  color: ${(props) => props.theme.color.textSecondary};
  width: 37px;
  text-align: right;
`
const StyledTextName = styled(Text)`
  font-size: ${(props) => props.theme.fontSize.base};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.base};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.base};
  }
  font-weight: ${(props) => props.theme.fontWeight.bold};
  color: ${(props) => props.theme.color.textSecondary};
  width: 100%;
  text-align: center;
`
const CurveWrapper = styled.div`
  position: relative;
  flex: 1;
  margin-left: -5%;
  margin-right: -5%;
  &:before {
    content: ' ';
    padding-top: 18%;
    display: block;
  }
`
const PathWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
`
const Path = styled.div`
  padding-top: 100%;
  width: 100%;
  border: 2px solid ${(props) => props.theme.color.textTertiary};
  border-radius: 50%;
`
const Icon = styled.div`
  background-color: white;
  width: 24px;
  height: 24px;
  position: absolute;
  transform: translate(-11px, -11px);
  border-radius: 50%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
`
const Sun = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 14px;
  height: 14px;
  margin: auto;
  background-color: #e6c111;
  border-radius: 50%;
`
const Moon = styled(Sun)`
  background-color: transparent;
  margin-left: 11px;
  margin-bottom: 7px;
  box-shadow: -5px 1.5px 0 0 #556b80;
`
const IconWrapper = styled.div`
  padding-top: 100%;
  width: 100%;
  position: absolute;
  top: 0;
`

class ViewRiseSet extends Component {
  constructor(props) {
    super(props)
    this.state = {
      date: Date.now(),
    }
  }

  tick() {
    this.setState({
      date: Date.now(),
    })
  }

  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), 30000)
  }

  componentWillUnmount() {
    clearInterval(this.timerID)
  }

  render() {
    const { rise, set, moon, ...props } = this.props
    let riseTime = moment(rise)
    let setTime = moment(set)
    if (riseTime > setTime) {
      riseTime = riseTime.subtract(1, 'days')
    }
    riseTime = +riseTime
    setTime = +setTime
    return (
      <Wrapper {...props}>
        <StyledTextTime>{moment(rise).format(TIME_FORMAT).replace("24:","00:")}</StyledTextTime>
        <CurveWrapper>
          <PathWrapper>
            <Path />
          </PathWrapper>
          <IconWrapper>
            <Delay value={this.state.date} initial={riseTime}>
              {(now) => {
                const hoursDay = 24
                const millisecondsDay = hoursDay * (60000 * 60)
                const showTime = setTime - riseTime
                const hideTime = millisecondsDay - showTime
                const middleTime = hideTime / 2
                const middleTimeOfDay = setTime + middleTime

                if (now < riseTime || now > setTime) {
                  if (now > setTime && now < middleTimeOfDay) {
                    now = setTime
                  } else {
                    now = riseTime
                  }
                }
                return (
                  <Motion defaultStyle={{ time: riseTime }} style={{ time: spring(now) }}>
                    {({ time }) => {
                      const [top, left] = timeToCoordinates(time, riseTime, setTime)
                      return (
                        <Icon
                          style={{
                            top: `calc((${top} * 50%) + 50%)`,
                            left: `calc((${left} * 50%) + 50%)`,
                          }}
                        >
                          {moon ? <Moon /> : <Sun />}
                        </Icon>
                      )
                    }}
                  </Motion>
                )
              }}
            </Delay>
          </IconWrapper>
        </CurveWrapper>
        <StyledTextTime>{moment(set).format(TIME_FORMAT).replace("24:","00:")}</StyledTextTime>
        <StyledTextName>{moon ? translate('moonshine') : translate('sunshine')}</StyledTextName>
      </Wrapper>
    )
  }
}

ViewRiseSet.defaultProps = {
  rise: moment().startOf('07:00').toISOString(),
  set: moment().startOf('20:00').toISOString(),
}

ViewRiseSet.propTypes = {
  rise: PropTypes.string,
  set: PropTypes.string,
  moon: PropTypes.bool,
}

export default ViewRiseSet

export {
  timeToCoordinates as ViewRiseSet0timeToCoordinates,
  Wrapper as ViewRiseSet0Wrapper,
  StyledTextTime as ViewRiseSet0StyledTextTime,
  StyledTextName as ViewRiseSet0StyledTextName,
  CurveWrapper as ViewRiseSet0CurveWrapper,
  PathWrapper as ViewRiseSet0PathWrapper,
  Path as ViewRiseSet0Path,
  IconWrapper as ViewRiseSet0IconWrapper,
  Icon as ViewRiseSet0Icon,
}
