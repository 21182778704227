import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import translate from '../../../../../../modules/translate'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { actionPOST } from '../../../../../../modules/fetch-data'
import userSession from '../../../../modules/user-session'
import options from '../../../../../../config/options'
import React from 'react'

const EmailDialogNotVerified = ({ open = false, onEmailSend = (res) => {}, onClose = () => {} }) => (
  <Dialog
    open={open}
    aria-labelledby="email-verification-dialog-title"
    aria-describedby="email-verification-dialog-description"
  >
    <DialogTitle id="email-verification-dialog-title">{translate('email_verification_dialog_title')}</DialogTitle>
    <DialogContent>
      <DialogContentText id="email-verification-dialog-description">
        {translate('email_verification_dialog_description')}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button color="primary" onClick={onEmailSend}>
        {translate('email_verification_send_button')}
      </Button>
      <Button onClick={onClose} color="primary">
        {translate('close')}
      </Button>
    </DialogActions>
  </Dialog>
)

export default EmailDialogNotVerified
